
type NavbarContent = {
    home: string;
    knockout: string;
}

type CategoryContent = {
    robotics: string;
    racing: string;
    sumo: string;  
}

type GroupContent = {
    group: string;
    team: string;
    win: string;
    draw: string;
    lose: string;
    time: string;
    points: string;
}

type RacingContent = {
    team: string;
    round1: string;
    round2: string;
    result: string;
}

type KnockOutConent = {
    title: string;
    semiFinal: string;
    quarterFinal: string;
    final: string;
    thirdPlace: string;
}

export type Content = {
    navbar: NavbarContent;
    category: CategoryContent;
    group: GroupContent;
    knockout: KnockOutConent;
    racing: RacingContent;
}

export type LanguageProps = {
    en: Content;
    kr: Content;
}

export const appContent: LanguageProps = {
    en: {
        navbar: {
            home: 'Home',
            knockout: 'KnockOut'
        },
        category: {
            robotics: 'Robotics',
            racing: 'Racing',
            sumo: 'Sumo'
        },
        group: {
            group: 'Group',
            team: 'Team',
            win: 'Win',
            draw: 'Draw',
            lose: 'Lose',
            time: 'Time',
            points: 'Points'
        },
        knockout: {
            title: 'KnockOut Stage',
            semiFinal: 'Semi Final',
            quarterFinal: 'Quarter Final',
            final: 'Final',
            thirdPlace: 'Third Place'
        },
        racing: {
            team: 'Team',
            round1: "Round 1",
            round2: "Round 2",
            result: 'Result'
        }
    },
    kr: {
        "navbar": {
            "home": "홈",
            "knockout": "녹아웃"
        },
        "category": {
            "robotics": "로봇 공학",
            "racing": "경주",
            "sumo": "스모"
        },
        "group": {
            "group": "그룹",
            "win": "승리",
            'team': '팀',
            "draw": "무승부",
            "lose": "패배",
            "time": "시간",
            "points": "점수"
        },
        "knockout": {
            "title": "녹아웃 스테이지",
            "semiFinal": "준결승",
            "quarterFinal": "8강",
            "final": "결승",
            "thirdPlace": "3위 결정전"
        },
        "racing": {
            "team": "팀",
            "round1": "1라운드",
            "round2": "2라운드",
            "result": "결과"
        }
    }    
}